var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lat
    ? _c("div", [
        _c("a", { attrs: { href: _vm.link, target: "_blank" } }, [
          _c(
            "p",
            { staticClass: "geo-string" },
            [
              _vm._v(_vm._s(_vm.text)),
              _vm.icon
                ? _c("FontAwesomeIcon", {
                    attrs: { icon: ["fas", "external-link-alt"], size: "sm" }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }