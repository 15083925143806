<template>
  <div v-if="lat">
    <a :href="link" target="_blank" ><p class="geo-string">{{text}}<FontAwesomeIcon v-if="icon" :icon="['fas', 'external-link-alt']" size="sm" />
     </p> 
    </a>
  </div>
</template>

<style scoped>
.geo-string{
  word-wrap: normal;
}
</style>
<script>
export default {
  name: "CGeocode",
  props: {
    lat: {
      type: String | Number
    },
    lon: {
      type: String | Number
    },
    url: {
      type: String, 
      default: "http://maps.google.com/maps"
    }, 
    icon: {
      type: Boolean,
      default: false,
    } 
  },
  computed: {
    text() {
        if(!this.lat) 
        return ""; 
        return `${this.lat}, ${this.lon}`; 
    },
    link() {
        if(!this.lat)
            return this.url + "?"; 
        return `${this.url}?q=${this.lat},${this.lon}`; 
    }
  }
};
</script>

